import React from "react"

import "animate.css"
import styled from "styled-components"

import rawIcon from "../images/sugar-cane.svg"
import distillationIcon from "../images/distillation.svg"
import agingIcon from "../images/barrel.svg"
import proofIcon from "../images/earning.svg"
import sugarIcon from "../images/sugar.svg"
import additivesIcon from "../images/pouring.svg"

export default function Process({
  material,
  distillation,
  aging,
  sugar,
  proof,
  additives,
}) {
  return (
    <ProcessWrapper>
      <ProcessElement
        className={`animate__animated ${
          typeof window !== "undefined" && window.innerWidth > 768
            ? "animate__slideInLeft"
            : "animate__slideInUp"
        }`}
      >
        <ProcessIcon src={rawIcon} alt="raw material" />
        <p>Raw material:</p>
        <p>{material}</p>
      </ProcessElement>

      <ProcessElement className="animate__animated animate__slideInUp">
        <ProcessIcon src={distillationIcon} alt="distillation" />
        <p>Distillation:</p>
        <p>{distillation}</p>
      </ProcessElement>

      <ProcessElement
        className={
          typeof window !== "undefined" && window.innerWidth > 768
            ? "animate__animated animate__slideInRight"
            : "animate__animated animate__slideInUp"
        }
      >
        <ProcessIcon src={agingIcon} alt="aging" />
        <p>Aging:</p>
        <p>{aging}</p>
      </ProcessElement>

      <ProcessElement
        className={
          typeof window !== "undefined" && window.innerWidth > 768
            ? "animate__animated animate__slideInLeft"
            : "animate__animated animate__slideInUp"
        }
      >
        <ProcessIcon src={sugarIcon} alt="sugar added" />
        <p>Sugar added:</p>
        <p>{sugar}</p>
      </ProcessElement>

      <ProcessElement className="animate__animated animate__slideInUp">
        <ProcessIcon src={proofIcon} alt="alcohol by volume" />
        <p>Alcohol by volume:</p>
        <p>{proof}&#37;</p>
      </ProcessElement>

      <ProcessElement
        className={
          typeof window !== "undefined" && window.innerWidth > 768
            ? "animate__animated animate__slideInRight"
            : "animate__animated animate__slideInUp"
        }
      >
        <ProcessIcon src={additivesIcon} alt="additives" />
        <p>Additives:</p>
        <p>{additives}</p>
      </ProcessElement>
    </ProcessWrapper>
  )
}

const ProcessWrapper = styled.div`
  @media (max-width: 600px) {
    margin: 1rem auto 1rem;
  }

  grid-area: process;
  display: flex;

  flex-wrap: wrap;

  justify-content: center;

  align-items: flex-start;
  margin: 3.5rem auto 1rem;

  p {
    font-size: 1.2rem;

    /* font-family: var(--montserrat); */
    font-family: "Montserrat", sans-serif;
  }
`

const ProcessElement = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  text-align: center;

  flex-basis: 140px;
  margin: 16px 8px;

  @media (min-width: 600px) {
    flex-basis: 200px;
    margin: 20px 12px;
  }

  p:nth-of-type(1) {
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
    font-size: 1.25rem;
    margin: 5px 0 8px;
  }

  p:nth-of-type(2) {
  }
`

const ProcessIcon = styled.img`
  justify-content: flex-start;
  width: 50px;
`
