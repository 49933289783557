import React from "react"

import styled from "styled-components"

import linkIcon from "../images/link2.svg"

export default function Taste({ declared, examined, website }) {
  return (
    <TastingWrapper>
      <TastingHeading>Afterburn</TastingHeading>
      {declared !== "-" ? (
        <Declared>
          <div>{declared}</div>
          <a href={website} target="_blank" rel="noopener noreferrer">
            &#x2014; as they tell
            <img src={linkIcon} alt="" />
          </a>
        </Declared>
      ) : (
        <NotDeclared>
          <div>Bouquet was not officially specifed.</div>
        </NotDeclared>
      )}

      <Examined>
        <div>{examined}</div>
        <span>&#x2014; as examined by RumExam</span>
        {/* <blockquote>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam
          delectus saepe, a quasi aperiam culpa quisquam quibusdam libero harum
          optio explicabo, sapiente totam cumque.
        </blockquote> */}
      </Examined>
    </TastingWrapper>
  )
}

const TastingWrapper = styled.div`
  grid-area: afterburn;
  margin: 1rem auto 3rem;

  font-size: 1.2rem;
  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;
`

const TastingHeading = styled.div`
  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;

  font-size: 2rem;
  position: relative;

  text-align: center;

  color: #becc2f;
  background-color: #000;

  letter-spacing: 2px;

  padding: 0.2rem 1rem;

  max-width: 620px;
  margin: 0 auto;

  @media (min-width: 500px) {
    min-width: 400px;
  }

  @media (min-width: 600px) {
    min-width: 500px;
  }

  @media (min-width: 700px) {
    min-width: 620px;
  }

  &::after {
    display: inline-block;

    content: "";
    position: absolute;
    top: 40px;
    left: 0;

    width: 100%;
    height: 20px;

    z-index: -10;

    background: repeating-linear-gradient(
      90deg,
      #e0d81d 0,
      #becc2f calc(1 / 3 * 100%),
      #becc2f 0,
      #7cba6d calc(2 / 3 * 100%),
      #7cba6d 0,
      #239a87 calc(3 / 3 * 100%)
    );
  }
`

const Declared = styled.div`
  position: relative;
  max-width: 620px;
  margin: 50px auto 0;
  text-align: center;

  div {
    /* font-family: var(--montserrat); */
    font-family: "Montserrat", sans-serif;
    position: relative;
    color: #becc2f;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    border: 2px solid #fff;

    border: solid 2px;
    border-radius: 20px;
    padding: 25px;
  }

  div:after {
    content: "";
    position: absolute;
    border: 2px solid #becc2f;
    border-radius: 0 50px 0 0;
    width: 80px;
    height: 30px;
    bottom: -32px;
    left: 50px;
    border-bottom: none;
    border-left: none;
    z-index: 3;
  }

  div:before {
    content: "";
    position: absolute;
    width: 80px;
    border: 6px solid #fff;
    bottom: -3px;
    left: 50px;
    z-index: 2;
  }

  span {
    position: relative;
    display: block;
    color: #becc2f;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding-top: 5px;
    z-index: 1;
    margin-left: 150px;
    padding-left: 12px;
  }

  a {
    display: flex;

    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
    text-decoration: none;
    text-align: left;

    padding-top: 10px;

    margin-left: 150px;
    padding-left: 12px;

    align-items: center;
    color: #becc2f;
  }

  img {
    width: 16px;
    margin-top: 2px;
    margin-left: 8px;
  }
`

const NotDeclared = styled.div`
  position: relative;
  max-width: 620px;
  margin: 50px auto 0;
  text-align: center;

  div {
    /* font-family: var(--montserrat); */
    font-family: "Montserrat", sans-serif;
    position: relative;
    color: #becc2f;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;

    border: solid 2px;
    border-radius: 20px;
    padding: 25px;
  }
`

const Examined = styled(Declared)`
  margin: 40px auto 1rem;

  div {
    color: #239a87;
  }

  div:after {
    border: 2px solid #239a87;
    border-bottom: none;
    border-left: none;
  }

  blockquote {
    text-align: left;
    margin: 2rem auto;
  }

  span {
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
    text-align: left;
    color: #239a87;
    padding-top: 10px;

    display: block;
    margin-left: 150px;
    padding-left: 12px;
  }
`
