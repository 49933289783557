import React from "react"
import { graphql } from "gatsby"

import ReactImageZoom from "react-image-zoom"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SEO from "../components/seo"

import styled from "styled-components"
import Layout from "./layout"
import Profile from "./Profile"
import Process from "./Process"
import Brand from "./Brand"
import Aroma from "./Aroma"
import Taste from "./Taste"
import Afterburn from "./Afterburn"

const Single = ({ data }) => {
  const {
    brand,
    manufacturer,
    slug,
    origin,
    regulations,
    style,
    gargano,
    material,
    distillation,
    aging,
    proof,
    sugar,
    additives,
    aroma,
    taste,
    afterburn,
    examaroma,
    examtaste,
    examafterburn,
    price,
    photo,
    website,
    published,
    // id,
    pretext: { json },
  } = data.single

  const options = {
    renderNode: {
      "heading-3": node => {
        return <Heading>{node.content[0].value}</Heading>
      },
      hyperlink: node => {
        return (
          <ExternalLink
            href={node.data.uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.content[0].value}
          </ExternalLink>
        )
      },
    },
    renderText: text =>
      text.split("<br>").flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }

  const seoContent = `${brand} comes from ${origin}. It has been classified as ${gargano}. It represents the ${style} style.`

  return (
    <Layout>
      <SEO title={brand} description={seoContent} />
      <ExamWrapper>
        <Brand brand={brand} slug={slug} />

        <Process
          material={material}
          distillation={distillation}
          aging={aging}
          sugar={sugar}
          proof={proof}
          additives={additives}
        ></Process>

        <Pretext>{documentToReactComponents(json, options)}</Pretext>

        <Aroma declared={aroma} examined={examaroma} website={website} />
        <Taste declared={taste} examined={examtaste} website={website} />
        <Afterburn
          declared={afterburn}
          examined={examafterburn}
          website={website}
        />

        <Profile
          manufacturer={manufacturer}
          price={price}
          origin={origin}
          regulations={regulations}
          style={style}
          gargano={gargano}
        />

        <Zoomer>
          <ReactImageZoom
            img={photo.fluid.src}
            zoomPosition="original"
            width={300}
          />
        </Zoomer>

        <ExamDate>
          &#8278;Reviewed on: <span>{published}</span>
        </ExamDate>
      </ExamWrapper>
    </Layout>
  )
}

export const query = graphql`
  query getSingle($slug: String!) {
    single: contentfulExam(slug: { eq: $slug }) {
      brand
      manufacturer
      slug
      origin
      regulations
      style
      gargano
      material
      distillation
      aging
      proof
      sugar
      additives
      aroma
      taste
      afterburn
      examaroma
      examtaste
      examafterburn
      price
      website

      id
      published(formatString: "MMMM Do, Y")

      photo {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
        file {
          url
        }
      }
      pretext {
        json
      }
    }
  }
`

const ExamWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: grid;

  grid-template-areas:
    "brand"
    "process"
    "pretext"
    "aroma"
    "taste"
    "afterburn"
    "profile"
    "zoomer"
    "examdate";

  @media (min-width: 700px) {
    grid-template-areas:
      "brand brand"
      "process process"
      "pretext pretext"
      "aroma aroma"
      "taste taste"
      "afterburn afterburn"
      "profile zoomer"
      "examdate examdate";
  }
`

const ExamDate = styled.div`
  grid-area: examdate;
  width: 100%;
  text-align: center;
  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;
  margin: 2rem auto;
`

const Zoomer = styled.div`
  grid-area: zoomer;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto 2rem;

  @media (min-width: 600px) {
    margin-top: 2rem;
    width: 100%;
  }
`

const Pretext = styled.span`
  grid-area: pretext;
  /* text-align: justify; */
  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;
  font-size: 1.15rem;
  width: 100%;
  margin: 0 auto 2.5rem;
  line-height: 1.35;

  text-align: left;
  hyphens: none;

  @media (min-width: 500px) {
    line-height: 1.4;
  }

  @media (min-width: 700px) {
    font-size: 1.2rem;
  }

  @media (min-width: 760px) {
    max-width: 90%;
  }

  @media (min-width: 880px) {
    line-height: 1.45;
    max-width: 85%;
  }

  @media (min-width: 940px) {
    font-size: 1.25rem;
    line-height: 1.45;
    max-width: 80%;
  }

  @media (min-width: 1132px) {
    max-width: 80%;
  }

  @media (min-width: 1460px) {
    font-size: 1.3rem;
    max-width: 78%;
  }
  /* @media (min-width: 900px) {
    width: 66%;
  } */
`

const Heading = styled.div`
  font-size: 1.8rem;

  text-align: center;
  letter-spacing: 2px;

  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;
  text-transform: uppercase;

  margin: 1.5rem auto;
`

const ExternalLink = styled.a`
  color: black;
  text-decoration: underline;
`

export default Single
